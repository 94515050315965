import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
  ) { }

  async presentNotifyAlert(
    header: string,
    message: string,
    button: string,
    cancelButtonNeeded: boolean = false,
    laterButtonNeeded: boolean = false) {
    // notify Alert will return boolean as a promise
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const buttonsList: Array<any> = [];
    if (cancelButtonNeeded) {
      buttonsList.push({
        text: 'Cancel',
        role: 'cancel',
        handler: () => { }
      });
    }
    // For app version update checking
    if (laterButtonNeeded) {
      buttonsList.push({
        text: 'Later',
        role: 'cancel',
        handler: () => { }
      });
    }
    buttonsList.push({
      text: button,
      handler: () => {
        resolveFunction(true);
      }
    });

    const alert = await this.alertController.create({
      header,
      message,
      buttons: buttonsList,
      backdropDismiss: false,
      // Can style if needed
      cssClass: 'alertPopup'
    });

    await alert.present();
    return promise;
  }
}
