import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private companyProfileData: any;
  private picProfileData: any;
  private additionalInfoProfileData: any;
  private companyDoc: any = [];
  private icDoc: any;
  private guarantorToggler: boolean;
  private isSigned = false;
  private applicationSignatureImages: any = {
    1: '',
    2: ''
  };
  private companyForm: any;
  private picProfileForm: any;
  private docUploadForm: any;
  private creditLimitForm: any;
  private referralForm: any;
  private referralCode: any;
  private questionnaireForm: any;
  private isBackToDocUpload = false;
  private androidVersion: string;
  private availableCreditLimit: any;
  private filePassword: string;
  private hasOnboardingDraft: any;
  private isDocUploadFormValid: boolean;
  private isKycDone = false;

  constructor() { }

  clearDataService() {
    this.companyProfileData = undefined;
    this.picProfileData = undefined;
    this.companyDoc = [];
    this.icDoc = undefined;
    this.guarantorToggler = false;
    this.isSigned = false;
    this.companyForm = undefined;
    this.picProfileForm = undefined;
    this.docUploadForm = undefined;
    this.creditLimitForm = undefined;
    this.referralForm = undefined;
    this.questionnaireForm = undefined;
    this.isBackToDocUpload = false;
    this.filePassword = '';
    this.hasOnboardingDraft = undefined;
    this.additionalInfoProfileData = undefined;
  }

  setFilePassword(data: string) {
    this.filePassword = data;
  }

  getFilePassword() {
    return this.filePassword;
  }

  setIsDocUploadFormValid(data: boolean) {
    this.isDocUploadFormValid = data;
  }

  getIsDocUploadFormValid() {
    return this.isDocUploadFormValid;
  }

  setHasOnboardingDraft(data: any) {
    this.hasOnboardingDraft = data;
  }

  getHasOnboardingDraft() {
    return this.hasOnboardingDraft;
  }

  setAvailableCreditLimit(data: any) {
    this.availableCreditLimit = data;
  }

  getAvailableCreditLimit() {
    return this.availableCreditLimit;
  }

  setAndroidVersion(data: string) {
    this.androidVersion = data;
  }

  getAndroidVersion() {
    return this.androidVersion;
  }

  setIsBackToDocUpload(data: boolean) {
    this.isBackToDocUpload = data;
  }

  getIsBackToDocUpload() {
    return this.isBackToDocUpload;
  }

  setCompanyProfileData(data: any) {
    this.companyProfileData = data;
  }

  getCompanyProfileData() {
    return this.companyProfileData;
  }

  setCompanyForm(data: any) {
    this.companyForm = data;
  }

  getCompanyForm() {
    return this.companyForm;
  }

  setPicProfileForm(data: any) {
    this.picProfileForm = data;
  }

  getPicProfileForm() {
    return this.picProfileForm;
  }

  setCreditLimitForm(data: any) {
    this.creditLimitForm = data;
  }

  getCreditLimitForm() {
    return this.creditLimitForm;
  }

  setQuestionnaireForm(data: any) {
    this.questionnaireForm = data;
  }

  getQuestionnaireForm() {
    return this.questionnaireForm;
  }

  setReferralForm(data: any) {
    this.referralForm = data;
  }

  getReferralForm() {
    return this.referralForm;
  }

  setReferralCode(data: any) {
    this.referralCode = data;
  }

  getReferralCode() {
    return this.referralCode;
  }
  setAdditionalInfoProfileData(data: any) {
    this.additionalInfoProfileData = data;
  }

  getAdditionalInfoProfileData() {
    return this.additionalInfoProfileData;
  }

  setDocUploadForm(data: any) {
    this.docUploadForm = data;
  }

  getDocUploadForm() {
    return this.docUploadForm;
  }

  setIsSignedFlag(flag: boolean) {
    this.isSigned = flag;
  }

  getIsSignedFlag() {
    return this.isSigned;
  }

  setApplicationSignatureImages(data: any) {
    this.applicationSignatureImages = data;
  }

  getApplicationSignatureImages() {
    return this.applicationSignatureImages;
  }

  setPicProfileData(data: Object) {
    this.picProfileData = data;
  }

  getPicProfileData() {
    return this.picProfileData;
  }

  setCompanyDoc(index, data: File[]) {
    this.companyDoc[index] = data;
  }

  getCompanyDoc(index) {
    return this.companyDoc[index];
  }

  getCompanyDocs() {
    return this.companyDoc;
  }

  setIcDoc(data: File) {
    this.icDoc = data;
  }

  getIcDoc() {
    return this.icDoc;
  }

  setIsKycDone(data: boolean) {
    this.isKycDone = data;
  }

  getIsKycDone() {
    return this.isKycDone;
  }
}
