import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss'],
})
export class HeaderToolbarComponent implements OnInit {

  // Title of header
  @Input() title: string;

  // Align the logo to center
  @Input() isCenter = false;

  // Logo in header
  @Input() hasLogo = false;

  // Back button in header
  @Input() hasBack = false;

  @Input() backUrl: string;

  @Input() titleOnly = false;

  constructor(
    private location: Location,
    private router: Router
    ) { }

  ngOnInit() {
  }

  // Return previous page
  goBack() {
    if (this.backUrl === undefined) {
      this.location.back();
    }
    else {
      this.router.navigateByUrl(`/${this.backUrl}`);
    }
  }

}
