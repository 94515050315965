import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'document-icon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.scss'],
})
export class DocumentIconComponent implements OnInit {

  // Title of header
  @Input() fileName: string;
  @Input() editable: boolean;
  @Output() actionClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  buttonClicked() {
    this.actionClicked.emit('removeClick');
  }

}
