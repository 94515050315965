import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderToolbarComponent } from './header-toolbar/header-toolbar.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DropDownListComponent } from './drop-down-list/drop-down-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material';
import { SignatureDrawComponent } from './signature-draw/signature-draw.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DocumentIconComponent } from './document-icon/document-icon.component';
import { PopUpModelComponent } from './pop-up-model/pop-up-model.component';
import { PopUpSelectionComponent } from './pop-up-selection/pop-up-selection.component';

@NgModule({
  declarations: [
    HeaderToolbarComponent,
    DropDownListComponent,
    SignatureDrawComponent,
    DocumentIconComponent,
    PopUpModelComponent,
    PopUpSelectionComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    SignaturePadModule
  ],
  exports: [
    HeaderToolbarComponent,
    DropDownListComponent,
    SignatureDrawComponent,
    DocumentIconComponent,
    PopUpModelComponent,
    PopUpSelectionComponent
  ],
  entryComponents: [HeaderToolbarComponent, DropDownListComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
