import { Component, OnInit, Injector } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { PopoverController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Storage } from '@ionic/storage';

const clientId = environment.myInfo.clientId;
// const attributes = 'name,email,mobileno,regadd,housingtype,marital,basic-profile';
const attributes = 'name,email,mobileno,regadd,housingtype,marital,basic-profile,sex,dob,residentialstatus,nationality,addresses,capitals,appointments,shareholders,financials,noahistory';
const purpose = 'Applying for Cash-IN-Asia Business Line of Credit Account.';
const redirectUrl = environment.myInfo.redirectUrl;

@Component({
  selector: 'app-pop-up-selection',
  templateUrl: './pop-up-selection.component.html',
  styleUrls: ['./pop-up-selection.component.scss'],
})
export class PopUpSelectionComponent implements OnInit {
  navExtras: NavigationExtras;
  storage: Storage;
  platform: Platform;
  userInfoSourceKey: string = 'userInfoSource';
  userInfoSourceValue: string = 'none';
  authCode: string;
  state: string;
  constructor(public popover: PopoverController, private iab: InAppBrowser, injector: Injector, private router: Router) {
    this.storage = injector.get(Storage);
    this.platform = injector.get(Platform);
  }

  ngOnInit() { }

  withoutMyinfo() {
    this.storage.set(this.userInfoSourceKey, this.userInfoSourceValue);
    this.popover.dismiss('myInfo');
  }

  callAuthoriseApi() {
    // Set source of the user info
    this.userInfoSourceValue = 'myInfo';
    this.storage.set(this.userInfoSourceKey, this.userInfoSourceValue);
    const authApiUrl = environment.myInfo.authURL;
    const authoriseUrl = authApiUrl + '?client_id=' + clientId +
      '&attributes=' + attributes +
      '&purpose=' + purpose +
      '&state=' + Date.now().toString() +
      '&redirect_uri=' + redirectUrl;

    if (this.platform.is('cordova')) {
      const browser = this.iab.create(authoriseUrl, '_blank', {
        location: 'no',
        clearcache: 'yes'
      });
      browser.on('loadstart').subscribe(event => {
        if (event.url.includes('intent')) {
          alert("This browser/app doesn't support auto launching of Singpass Mobile, Please login with CorpPass ID & Password.");
          this.callAuthoriseApi();
        }
        if (event.url.includes(environment.myInfo.redirectUrl)) {
          if (event.url.includes('error-description')) {
            browser.close();
          }
          else {
            this.getParams(event.url);
            const navExtras: NavigationExtras = {
              state: {
                state: this.state,
                code: this.authCode
              }
            };
            browser.close();
            this.router.navigateByUrl('/auth2', navExtras);
          }
        }
      });
    }
    else {
      this.iab.create(authoriseUrl, '_self', {
        clearcache: 'yes'
      });
    }
  }

  getParams(url) {
    if (url.indexOf('?') > 0) {
      const splitURL = url.split('?');
      const splitParams = splitURL[1].split('&');
      let i: any;
      // tslint:disable-next-line: forin
      for (i in splitParams) {
        const singleURLParam = splitParams[i].split('=');
        if (singleURLParam[0] === 'code') {
          this.authCode = singleURLParam[1];
        }
        if (singleURLParam[0] === 'state') {
          this.state = singleURLParam[1];
        }
      }
    }
  }
}
