import { Component, ElementRef, NgZone } from '@angular/core';
import { Injector } from '@angular/core';

import { Platform, Events, AlertController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Storage } from '@ionic/storage';
import { Idle, EventTargetInterruptSource, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { DataService } from './services/data.service';
import { CognitoServiceProviderService } from './services/cognito-service/cognito-service-provider.service';
import { DrawdownServiceService } from './services/data-services/drawdown-service.service';
import { AlertService } from './services/alert/alert.service';
import { Keepalive } from '@ng-idle/keepalive';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { environment } from 'src/environments/environment';
import { AuthPage } from './pages/auth/auth.page';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
// import { filter } from 'rxjs/operators';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  alertService: AlertService;

  // WARNING: Do not change the value of src for each tab as it will be used in other functionality
  bottomTabs = [
    {
      name: 'Dashboard',
      url: 'home',
      src: 'dashboard',
      disabled: false
    },
    {
      name: 'Get Cash',
      url: 'drawdown-application',
      src: 'loan',
      disabled: true
    },
    {
      name: 'Calculator',
      url: 'calculator',
      src: 'calculator',
      disabled: true
    },
    {
      name: 'Profile',
      url: 'profile-settings',
      src: 'profile',
      disabled: true
    }
  ];
  timer;
  timedOut = false;
  allowedIdleState = 600;
  timeoutWarning = 0;
  lastPing?: Date = null;
  // ga: Function;
  // gtag: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private storage: Storage,
    private events: Events,
    private translate: TranslateService,
    public idle: Idle,
    public element: ElementRef,
    public alertController: AlertController,
    private zone: NgZone,
    injector: Injector,
    private event: Events,
    private dataService: DataService,
    private cognitoService: CognitoServiceProviderService,
    private drawdownService: DrawdownServiceService,
    private navCtrl: NavController,
    private keepalive: Keepalive,
    private deeplinks: Deeplinks,
    private router: Router
  ) {
    this.initializeApp();
    translate.setDefaultLang('en');
    translate.use('en');
    this.events.subscribe('login', (user) => {
      this.getUserInfoFromStorage();
      this.disableDrawdownAndCalc();
    });
    this.events.subscribe('logout', (user) => {
      this.disableBottomTab();
    });
    this.events.subscribe('kycApproved', (user) => {
      this.enableDrawdown();
    });
    this.alertService = injector.get(AlertService);

    this.configureSVGIcons([
      { iconName: 'dashboard', iconURL: 'assets/icons/dashboard.svg' },
      { iconName: 'loan', iconURL: 'assets/icons/loan.svg' },
      { iconName: 'calculator', iconURL: 'assets/icons/calculator.svg' },
      { iconName: 'profile', iconURL: 'assets/icons/profile.svg' },
      { iconName: 'back', iconURL: 'assets/icons/back.svg' }
    ]);

    // const navEndEvents = router.events.pipe(
    //   filter(event => event instanceof NavigationEnd),
    // );
    // navEndEvents.subscribe((event: NavigationEnd) => {
    //   this.gtag('config', environment.googleAnalyticsTrackingId, {
    //     'page_path': event.urlAfterRedirects
    //   });
    // })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
      this.getUserInfoFromStorage();
      this.disableDrawdownAndCalc();

      this.deeplinks.route({
        '/auth': { auth: true },
        '/auth2': { auth2: true }
      }).subscribe(match => {
        console.log(match.$args);
        const navExtras: NavigationExtras = {
          state: {
            state: match.$args.state,
            code: match.$args.code
          }
        };
        console.log('Successfully matched route', match);
        this.zone.run(async () => {
          console.log('Navigating to auth page');
          const path = match.$link.path.split('/')[1]; // To get the path from the match object
          this.router.navigateByUrl(path, navExtras);
        });
      }, nomatch => {
        // nomatch.$link - the full link data
        console.error('Got a deeplink that didn\'t match', nomatch);
      });
    });
  }

  enableDrawdown() {
    this.bottomTabs.forEach(tab => {
      if (tab.src === 'loan') {
        tab.disabled = false;
      }
    });
  }

  disableBottomTab() {
    this.bottomTabs.forEach(tab => {
      if (tab.src === 'loan' || tab.src === 'profile' || tab.src === 'calculator') {
        tab.disabled = true;
      }
      if (tab.src === 'dashboard') {
        tab.url = 'home';
      }
    });
  }
  disableDrawdownAndCalc() {
    this.storage.get('USER_INFO').then(async data => {
      let referralCode = data.agentCode;

      console.log("referral!!", referralCode)

      if (environment.referral[referralCode]) {
        this.bottomTabs.forEach(tab => {

          if (tab.src === 'loan' || tab.src === "calculator") {
            tab.url = 'dashboard';
          }
        });
      }

    });



  }

  getTranslateObservable(translateString: string) {
    let temp: any;

    try {
      this.translate.get(translateString).subscribe(value => {
        temp = value;
      });
    }
    catch (err) {
      temp = '';
    }
    return temp;
  }
  checkReferral(name) {
    let referralCode = "";
    referralCode = this.dataService.getReferralCode();


    if (environment.referral[referralCode]) {
      if (name === "Get Cash" || name === "Calculator") {
        this.alertService.presentNotifyAlert(null,
          this.getTranslateObservable('alertBox.messages.referralRestriction'),
          this.getTranslateObservable('alertBox.buttons.ok'));

      }
    }

  }
  getUserInfoFromStorage() {
    this.storage.get('USER_INFO').then((data) => {
      console.log(data);
      if (data) {
        this.bottomTabs.forEach(tab => {
          if (tab.src === 'profile') {
            tab.disabled = false;
          }
          if (tab.src === 'dashboard') {
            tab.url = 'dashboard';
          }
          if (tab.src === 'calculator') {
            tab.disabled = false;
          }
        });
      }
    });
  }

  private configureSVGIcons(customSVGIconList: Array<CustomSVGIcon>): void {
    customSVGIconList.forEach(customSVGIcon => this.configureSVGIcon(customSVGIcon));
  }

  private configureSVGIcon(customSVGIcon: CustomSVGIcon): void {
    this.matIconRegistry.addSvgIcon(customSVGIcon.iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(customSVGIcon.iconURL));
  }

  public hideTabs() {
    const tabBar = document.getElementById('myTabBar');
    if (tabBar.style.display !== 'none') { tabBar.style.display = 'none'; }
  }

  public showTabs() {
    const tabBar = document.getElementById('myTabBar');
    if (tabBar.style.display !== 'flex') { tabBar.style.display = 'flex'; }
  }

  public startWatchingIdle() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(this.allowedIdleState);

    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(this.timeoutWarning);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // example interupt: mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll click
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onInterrupt.subscribe(() => {
      window.clearInterval(this.timer);
      console.log('Interrupt...');
    });

    this.idle.onIdleStart.subscribe(() => {
      console.log('Idle start');
      this.createAlert(null, this.translate.instant('idle.logoutNote'), null);
    });

    this.idle.onIdleEnd.subscribe(() => {
      console.log('No longer idle.');
      window.clearInterval(this.timer);
    });

    this.idle.onTimeout.subscribe(() => {
      console.log('Timed out!');
      this.timedOut = true;
      this.createAlert(null, this.translate.instant('idle.logoutNote'), null);
    });

    this.idle.onTimeoutWarning.subscribe((countdown => {
      console.log('Start Counting: ', countdown);
      // if (countdown === this.timeoutWarning) {
      //   console.log('Start Counting');
      //   const alertMessage = this.translate.instant('idle.countdownTimerNote') + countdown +
      //     this.translate.instant('idle.countdownTimerNote2');
      //   this.createAlert('warningTimerAlert', alertMessage, countdown);
      // }
    }));

    // sets the ping interval to 30 seconds
    this.keepalive.interval(30);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      console.log('Pinging: ', this.lastPing);
    });

    this.reset();
  }

  async createLogoutAlert(type: string, message: string, countdown: number) {
    const buttonsList: Array<any> = [];
    buttonsList.push({
      text: 'Ok',
      role: 'ok',
      handler: () => {
        this.clearCacheAndStorage();
        this.zone.run(async () => {
          this.event.publish('logout', []);
          this.navCtrl.navigateRoot('/home');
        });
      }
    });

    const alert = await this.alertController.create({
      message,
      buttons: buttonsList,
      backdropDismiss: false
    });

    this.idle.stop();
    this.idle.onInterrupt.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onTimeout.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
    this.idle.ngOnDestroy();
    window.clearInterval(this.timer);
    alert.dismiss();
  }

  reset() {
    this.idle.watch();
    console.log('Watch Idle');
    this.timedOut = false;
  }

  async createAlert(type: string, message: string, countdown: number) {
    const buttonsList: Array<any> = [];
    if (type === 'warningTimerAlert') {
      buttonsList.push({
        text: 'Cancel',
        role: 'cancel',
        handler: () => { }
      });
    }
    else {
      console.log('Force logout');
      this.clearIdle();
      buttonsList.push({
        text: 'Ok',
        role: 'ok',
        handler: () => {
          this.clearCacheAndStorage();
          this.zone.run(async () => {
            this.event.publish('logout', []);
            this.navCtrl.navigateRoot('/home');
          });
        }
      });
    }

    const alert = await this.alertController.create({
      message,
      buttons: buttonsList,
      backdropDismiss: false
    });

    if (type === 'warningTimerAlert') {
      window.clearInterval(this.timer);
      this.timer = setInterval(() => {
        countdown--;
        if (countdown !== 0) {
          alert.message = this.translate.instant('idle.countdownTimerNote') + countdown +
            this.translate.instant('idle.countdownTimerNote2');
        }
        else {
          this.clearIdle();
          window.clearInterval(this.timer);
          alert.dismiss();
          this.createAlert(null, this.translate.instant('idle.logoutNote'), null);
        }
      }, 1000);
    }
    await alert.present();
  }

  clearIdle() {
    console.log('Clear Idle');
    this.idle.stop();
    this.idle.onInterrupt.observers.length = 0;
    this.idle.onIdleStart.observers.length = 0;
    this.idle.onIdleEnd.observers.length = 0;
    this.idle.onTimeout.observers.length = 0;
    this.idle.onTimeoutWarning.observers.length = 0;
    this.idle.ngOnDestroy();
  }

  async clearCacheAndStorage() {
    console.log('Clean cache and storage');
    this.dataService.clearDataService();
    this.cognitoService.logOut();
    this.drawdownService.clearAllDrawdownInfo();
    this.events.publish('logout', []);
    this.storage.clear();
  }
}

interface CustomSVGIcon {
  iconName: string;
  iconURL: string;
}
