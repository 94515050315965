export const environment = {
  production: false,
  aws: {
    cognito: {
      user_pool: {
        region: 'ap-southeast-1',
        // TDDO (Uncomment below when you want to use back old account in old user pool)
        //Email
        // user_pool_id: 'ap-southeast-1_IB5VAiYNX',
        // app_client_id: '5kmjbdq8ntqbo33ksvgvkgrjk3'

        //SMS
        user_pool_id: 'ap-southeast-1_f7NOVBMMA',
        app_client_id: '6cg2h9tuf06ejo41rdu7pf5k25'

        //OIDC
        // user_pool_id: 'ap-southeast-1_1mR4kuLIm',
        // app_client_id: '52csb22nu0ov73ka37ht2pbqdk'
      },
      identity_pool: {
        region: 'ap-southeast-1',
        identity_pool_id: 'ap-southeast-1:c39fea6f-74bf-4c3a-9877-7ccd8a082246'
        // identity_pool_id: 'ap-southeast-1:450955199439:userpool/ap-southeast-1_1mR4kuLIm'
      }
    },
    s3: {
      region: 'ap-southeast-1',
      appBucket: 'calms-uat-storage'
    },
    // TODO: To be removed once public URL is ready, temporary to get app version info.
    appVersion: {
      region: 'ap-southeast-1',
      appBucket: 'calms-uat-app-version'
    }
  },
  singpass: {
    url: 'https://stg-saml.singpass.gov.sg/mga/sps/oauth/oauth20/authorize'
  },
  credolab: {
    url: 'https://scoring-demo.credolab.com',
    authKey: '92ae1c54-e22c-4056-8461-f4c998070e89',
    componentCode: {
      android: '1',
      ios: '2'
    },
    username: 'admin@cashinasia.com',
    password: 'ckxRP4J3$h'
  },
  myInfo: {
    redirectUrl: 'https://capitaluat.cashinasia.com/auth2',
    authURL: 'https://test.api.myinfo.gov.sg/biz/v1/authorise',
    clientId: 'STG-201614262R-CASHINASIA-LOAN-APPL'
  },
  apiUrl: {
    name: 'calms-api-gateway',
    region: 'ap-southeast-1',
    hostUrl: 'https://calms-uat.cashinasia.com/api',
    apiPath: {
      company: '/company',
      userProfiles: '/userProfiles',
      creditLimits: '/creditLimits',
      documentCheckList: '/documentCheckList',
      drawdowns: '/drawdown',
      loanProduct: '/LoanProducts',
      guarantor: '/guarantor',
      repayment: '/repayment',
      statement: '/statement',
      termLoanAgreement: '/TermLoanAgreements',
      creditFacilityAgreement: '/CreditFacilityAgreements',
      termLoanEmail: '/email/createTermLoanEmail',
      creditFacilityEmail: '/api/email/createCreditFacilityEmail',
      signature: '/signature',
      guarantorAgreement: '/GuarantorAgreements',
      guarantorAgreementForCreditFacility: '/GuarantorAgreements/createAgreementGuarantorCreditFacility',
      agentList: '/agentList',
      appVersion: '/appVersion',
      onboardingDraft: '/onboardingDraft',
      singpass: '/singPass',
      credolab: '/userCredo',
      appVersions: '/appVersions',
      myInfo: '/myInfo',
      myInfoPersonalProfile: '/myInfoPersonalProfile',
      myInfoBusProfile: '/myInfoBusProfile',
      myInfoAdditionalInfo: '/myInfoAdditionalInfo',
      office: '/office',
      statementEntries: '/StatementEntries',
      code: '/code',
      portfolioCommandSource: '/portfolioCommandSource',
      fineract: '/fineracts',
      savingsProduct: '/savingsProduct',
      savingsAccount: '/SavingsAccount',
      savingsAccountsRepayment: '/savingsAccountsRepayment',
      remarks: '/remarks'
    }
  },
  finUrl: {
    name: 'calms-api-gateway-fin',
    region: 'ap-southeast-1',
    hostUrl: 'https://calms-uat.cashinasia.com/fineract-provider/api/v1',
    apiPath: {

    }
  },
  // This url is for Credit line integration on LMS-157 in jira
  lmsUrl: {
    name: 'calms-api-gateway-lms-uat',
    region: 'ap-southeast-1',
    hostUrl: 'https://l4yyhlafn9.execute-api.ap-southeast-1.amazonaws.com/calms-uat-temp/api',
    apiPath: {
      loanProduct: '/LoanProducts',
      drawdowns: '/drawdown',
      statement: '/statement',
      repayment: '/repayment',
      company: '/company'
    }
  },
  storage: {
    tnc: 'TNC/CA_Web_App_Terms_of_Use_20200327.pdf'
  },
  // TODO: change to CA's whatsapp business account
  whatsappNumber: '+6566355668',
  phoneNumber: '+6566355668',
  webUrl: 'https://capitaluat.cashinasia.com',
  office: {
    name: "Cash In Asia Office"
  },
  enumStatusValue: {
    paymentProcessingEnum: {
      pendingApproval: 2,
      approve: 1,
      reject: 3
    },
    drawdownEnum: {
      pendingApproval: 100,
      approve: 200,
      reject: 500,
      active: 300,
      close: 600,
      overpaid: 700
    },
    pendingOnboarding: 50,
    pendingApproval: 100,
    approve: 300,
    reject: 700,
    close: 600
  },
  legalFormEnum: {
    entityForm: 2
  },
  constitution: {
    name: "Default"
  },
  updatedAndApprovedById: 5,
  termLoanApplication: {
    feeCharges: {
      admin: {
        code: "ADMIN"
      }
    }
  },
  creditLineApplication: {
    feeCharges: {
      admin: {
        code: "ADMIN"
      }
    }
  },
  referral: {
    "GUUDGSTFIN": true,
  }
};
