import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownListComponent),
      multi: true
    }
  ]
})
export class DropDownListComponent implements ControlValueAccessor {

  // Event
  @Output() change: EventEmitter<any>;

  // Array object
  @Input() options: Array<object> = [];

  // Object property to use for label
  @Input() bindLabel: string = null;

  // Placeholder of the drop-down list
  @Input() placeholder: string = null;

  // Enable virtual scroll for better performance when rendering a lot of data
  @Input() virtualScroll = false;

  // Allow to search for value. Default = true
  @Input() searchable = true;

  // Selected Attribute
  @Input() selectedAttribute: string = null;

  // Form validation - Removed after adding form validation in all pages
  @Input() isForm = false;
  @Input() formGroup: string = null;
  @Input() formControlName: string = null;

  // Allow to clear selected value. Default true
  @Input() clearable = true;

  @Input() readonly = false;

  constructor() {
    this.change = new EventEmitter<any>();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() { }

  onChange(event) {
    this.change.emit(event);
    this.onTouched();
  }

  // ControlValueAccessor Implementation
  onTouched: any = () => { };

  registerOnChange() { }

  registerOnTouched() { }

  writeValue() { }

  setDisabledState() { }
}
