import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'statement',
    loadChildren: () => import('./pages/statement/statement.module').then(m => m.StatementPageModule)
  },
  {
    path: 'statement-details',
    loadChildren: () => import('./pages/statement-details/statement-details.module').then(m => m.StatementDetailsPageModule)
  },
  {
    path: 'drawdown-application',
    loadChildren: () => import('./pages/drawdown-application/drawdown-application.module').then(m => m.DrawdownApplicationPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./pages/otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'guarantor-information',
    loadChildren: () => import('./pages/guarantor-information/guarantor-information.module').then( m => m.GuarantorInformationPageModule)
  },
  {
    path: 'company-profile',
    loadChildren: () => import('./pages/company-profile/company-profile.module').then( m => m.CompanyProfilePageModule)
  },
  {
    path: 'doc-upload',
    loadChildren: () => import('./pages/doc-upload/doc-upload.module').then( m => m.DocUploadPageModule)
  },
  {
    path: 'credit-limit',
    loadChildren: () => import('./pages/credit-limit/credit-limit.module').then( m => m.CreditLimitPageModule)
  },
  {
    path: 'pic-profile',
    loadChildren: () => import('./pages/pic-profile/pic-profile.module').then( m => m.PicProfilePageModule)
  },
  {
    path: 'drawdown-e-signature',
    loadChildren: () => import('./pages/drawdown-e-signature/drawdown-e-signature.module').then( m => m.DrawdownESignaturePageModule)
  },
  {
    path: 'drawdown-history',
    loadChildren: () => import('./pages/drawdown-history/drawdown-history.module').then( m => m.DrawdownHistoryPageModule)
  },
  {
    path: 'application-e-signature',
    loadChildren: () => import('./pages/application-e-signature/application-e-signature.module').then( m => m.ApplicationESignaturePageModule)
  },
  {
    path: 'repayment-history',
    loadChildren: () => import('./pages/repayment-history/repayment-history.module').then( m => m.RepaymentHistoryPageModule)
  },
  {
    path: 'repayment',
    loadChildren: () => import('./pages/repayment/repayment.module').then( m => m.RepaymentPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'profile-settings',
    loadChildren: () => import('./pages/profile-settings/profile-settings.module').then( m => m.ProfileSettingsPageModule)
  },
  {
    path: 'drawdown-confirmation',
    loadChildren: () => import('./pages/drawdown-confirmation/drawdown-confirmation.module').then( m => m.DrawdownConfirmationPageModule)
  },
  {
    path: 'credit-limit-summary',
    loadChildren: () => import('./pages/credit-limit-summary/credit-limit-summary.module').then( m => m.CreditLimitSummaryPageModule)
  },
  {
    path: 'drawdown-summary',
    loadChildren: () => import('./pages/drawdown-summary/drawdown-summary.module').then( m => m.DrawdownSummaryPageModule)
  },
  {
    path: 'questionnaire',
    loadChildren: () => import('./pages/questionnaire/questionnaire.module').then( m => m.QuestionnairePageModule)
  },
  {
    path: 'calculator',
    loadChildren: () => import('./pages/calculator/calculator.module').then( m => m.CalculatorPageModule)
  },
  {
    path: 'referral',
    loadChildren: () => import('./pages/referral/referral.module').then( m => m.ReferralPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'singpass-sign-in',
    loadChildren: () => import('./pages/singpass-sign-in/singpass-sign-in.module').then( m => m.SingpassSignInPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'login-with-otp',
    loadChildren: () => import('./pages/login-with-otp/login-with-otp.module').then( m => m.LoginWithOtpPageModule)
  },
  {
    path: 'auth2',
    loadChildren: () => import('./pages/auth2/auth2.module').then( m => m.Auth2PageModule)
  },
  {
    path: 'additional-info',
    loadChildren: () => import('./pages/additional-info/additional-info.module').then( m => m.AdditionalInfoPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
