import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AlertController } from '@ionic/angular';

// tslint:disable: object-literal-shorthand only-arrow-functions

@Injectable({
  providedIn: 'root'
})

export class CognitoServiceProviderService {
  cognitoUser = null;

  constructor(private router: Router, public alertController: AlertController) { }

  signUp(email: string, password: string, phoneNumber: string, referralCode: string) {
    return new Promise(async (resolved, reject) => {
      const attributes = {
        email: email,
        phone_number: phoneNumber
      };
      if (referralCode) {
        attributes['custom:referralCode'] = referralCode;
      }
      Auth.signUp({
        username: email,
        password,
        attributes
      }).then(result => {
        resolved(result);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  confirmUser(verificationCode, username) {
    return new Promise((resolved, reject) => {
      Auth.confirmSignUp(username, verificationCode)
        .then(result => { resolved(result); })
        .catch(error => { reject(error); });
    });
  }

  resetPassword(username: string) {
    return new Promise((resolved, reject) => {
      Auth.forgotPassword(username)
        .then(result => {
          console.log(result);
          resolved(result);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  confirmResetPassword(username: string, code, newPassword) {
    return new Promise((resolved, reject) => {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then(result => {
          resolved(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // For Login
  authenticate(email: string, password: string) {
    return new Promise((resolved, reject) => {
      const username = email;
      // clear cache & sign out previouse logged in user before signing in
      // (so that auth returns most updated user info)
      // this.cache.logoutClear();
      Auth.signOut().then(() => {
        Auth.signIn({
          username,
          password
        }).then(user => {
          console.log('signedInUser:', user);
          resolved(user);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  // For Sign Up
  validateUser(email: string, password: string) {
    return new Promise((resolved, reject) => {
      const username = email;
      // clear cache & sign out previouse logged in user before signing in
      // (so that auth returns most updated user info)
      // this.cache.logoutClear();
      Auth.signOut().then(() => {
        Auth.signIn({
          username,
          password
        }).then(user => {
          Auth.setPreferredMFA(user, 'SMS');
          console.log('signedInUser:', user);
          resolved(user);
        }).catch(error => {
          reject(error);
        });
      });
    });
  }

  sendMFACode(user, mfaCode) {
    return new Promise(async (resolve, reject) => {
      Auth.confirmSignIn(user, mfaCode, 'SMS_MFA').then(result => {
        console.log('sendMFACode result: ', result);
        resolve(result);
      }).catch(error => {
        console.log(error);
        reject(error);
      });
    });
  }

  reconfirmUser(username: string) {
    return new Promise((resolved, reject) => {
      Auth.resendSignUp(username)
        .then(result => {
          resolved(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getLoggedUser() {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => resolve(user))
        .catch(err => reject(err));
    });
  }


  logOut() {
    return new Promise((resolved, reject) => {
      Auth.signOut()
        .then(result => {
          console.log('Signed Out User');
          resolved(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


  retrieveUserInfo() {
    return new Promise(async (resolve, reject) => {
      await Auth.currentUserInfo()
        .then(userInfo => {
          resolve(userInfo);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  changePassword(oldPassword: string, newPassword: string) {
    return new Promise((resolved, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(result => {
          resolved(result);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getCognitoIdentityCredentials() {
    const authRes = await Auth.currentSession();
    const cognitoIdentityCredentials = {
      identityPoolId: environment.aws.cognito.identity_pool.identity_pool_id,
      region: environment.aws.cognito.user_pool.region,
      userPoolId: environment.aws.cognito.user_pool.user_pool_id,
      jwtToken: authRes.getIdToken().getJwtToken()
    };

    return cognitoIdentityCredentials;
  }
}
