import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrawdownServiceService {

  private drawdownInfo: any;
  private eSignatureInfo: any;
  private drawdownDetail: any;
  private guarantorInformation: any;
  private drawdownSubmissionResponse: any;

  constructor() {

  }

  clearAllDrawdownInfo() {
    this.drawdownInfo = undefined;
    this.eSignatureInfo = undefined;
    this.drawdownDetail = undefined;
    this.guarantorInformation = undefined;
    this.drawdownSubmissionResponse = undefined;
  }

  setDrawdownSubmissionResponse(data: any) {
    this.drawdownSubmissionResponse = data;
  }

  getDrawdownSubmissionResponse() {
    return this.drawdownSubmissionResponse;
  }

  setGuarantorInformation(data: any) {
    this.guarantorInformation = data;
  }

  getGuarantorInformation() {
    return this.guarantorInformation;
  }

  setDrawdownDetail(data: any) {
    this.drawdownDetail = data;
  }

  getDrawdownDetail() {
    return this.drawdownDetail;
  }

  setDrawdownInfo(data: any) {
    this.drawdownInfo = data;
  }

  getDrawdownInfo() {
    return this.drawdownInfo;
  }

  setESignatureInfo(data: any) {
    this.eSignatureInfo = data;
  }

  getESignatureInfo() {
    return this.eSignatureInfo;
  }
}
