import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Storage from '@aws-amplify/storage';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';

Auth.configure({
  Auth: {
    identityPoolId: environment.aws.cognito.identity_pool.identity_pool_id,
    identityPoolRegion: environment.aws.cognito.identity_pool.region,
    region: environment.aws.cognito.user_pool.region,
    userPoolId: environment.aws.cognito.user_pool.user_pool_id,
    userPoolWebClientId: environment.aws.cognito.user_pool.app_client_id
  }
});

Storage.configure({
  Storage: {
    AWSS3: {
      bucket: environment.aws.s3.appBucket,
      region: environment.aws.s3.region
    }
  }
});

API.configure({
  API: {
    endpoints: [
      {
        name: environment.apiUrl.name,
        endpoint: environment.apiUrl.hostUrl,
        region: environment.apiUrl.region
      }
      ,
      {
        name: environment.lmsUrl.name,
        endpoint: environment.lmsUrl.hostUrl,
        region: environment.lmsUrl.region
      }
    ]
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
