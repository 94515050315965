import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ComponentsModule } from './components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { IonicStorageModule } from '@ionic/storage';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule, MatIconRegistry } from '@angular/material';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { PopUpModelComponent } from './components/pop-up-model/pop-up-model.component';
import { PopUpSelectionComponent } from './components/pop-up-selection/pop-up-selection.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    PopUpModelComponent,
    PopUpSelectionComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    AmplifyAngularModule,
    NgSelectModule,
    MatIconModule,
    PdfViewerModule,
    NoopAnimationsModule,
    SignaturePadModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
    }),
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md'
    }),
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    AndroidPermissions,
    AmplifyService,
    StatusBar,
    SplashScreen,
    Deeplinks,
    InAppBrowser,
    MatIconRegistry,
    AppVersion,
    Camera,
    Market,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
