import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SignaturePad, Point } from 'angular2-signaturepad/signature-pad';

@Component({
  selector: 'app-signature-draw',
  templateUrl: './signature-draw.component.html',
  styleUrls: ['./signature-draw.component.scss'],
})
export class SignatureDrawComponent implements OnInit {
  @ViewChild('signaturePad', { static: false }) public signaturePad: SignaturePad;
  @Input() canvasWidth: number;
  @Input() canvasHeight: number;
  @Input() dotSize = 1;
  @Input() minWidth = 0.5;
  @Input() backgroundColor = '#ffffff';
  @Input() signatureArray: any;
  @Output() saveImageUrl = new EventEmitter();
  @Output() clearImage = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.signatureArray = ''
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', this.minWidth);
    this.signaturePad.set('dotSize', this.dotSize);
    this.signaturePad.set('canvasWidth', this.canvasWidth);
    this.signaturePad.set('canvasHeight', this.canvasHeight);
    this.signaturePad.set('backgroundColor', this.backgroundColor);
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    if (this.signatureArray !== '') {
      this.signaturePad.fromData(this.signatureArray);
    }
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    const signatureObject = {
      dataUri: this.signaturePad.toDataURL(),
      dataArr: this.signaturePad.toData()
    };
    this.saveImageUrl.emit(signatureObject);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log(this.canvasHeight, this.canvasWidth);
    console.log('begin drawing');
  }

  clear(): void {
    this.signaturePad.clear();
    this.saveImageUrl.emit('');
    this.clearImage.emit(true);
  }

  // resizeCanvas(): void {
  //   var ratio = Math.max(window.devicePixelRatio || 1, 1);
  //   this.signaturePad.width = canvas.offsetWidth * ratio;
  //   canvas.height = canvas.offsetHeight * ratio;
  //   canvas.getContext("2d").scale(ratio, ratio);
  //   signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  // }
}
