import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-pop-up-model',
  templateUrl: './pop-up-model.component.html',
  styleUrls: ['./pop-up-model.component.scss'],
})
export class PopUpModelComponent implements OnInit {

  constructor(
    private iab: InAppBrowser
  ) { }

  ngOnInit() {
  }

  toExternalWeb() {
    this.iab.create(`${environment.webUrl}/sign-in`, '_system', {
      location: 'yes'
    });
  }
}
